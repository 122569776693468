const hostname = window.location.hostname;

const url = process.env.REACT_APP_LOCAL_BACKEND;

var apiUrl = '';
var appUrl = '';
var messageServiceUrl = '';

if (hostname.includes('localhost')) {
  apiUrl = url;
  messageServiceUrl = 'http://localhost:3001';

  // apiUrl = 'https://api.workhorsescs.pro'
  // apiUrl = 'https://api-prod.workhorsescs.pro'
  if (hostname.includes('3000')) {
    appUrl = 'http://localhost:3000';
  } else {
    appUrl = 'http://localhost:3000';
  }
}

if (hostname.includes('dev.workhorsescs.pro')) {
  apiUrl = 'https://dev.workhorsescs.pro:8443';
  appUrl = 'https://staging.workhorsescs.pro';
}

if (hostname.includes('developer.workhorsescs.pro')) {
  apiUrl = 'https://developer-api.workhorsescs.pro';
  appUrl = 'https://developer.workhorsescs.pro';
}

if (
  hostname.includes('staging.workhorsescs.pro') ||
  hostname.includes('vigilant-agnesi-9648f7.netlify.app')
) {
  //apiUrl = 'https://cors-anywhere.herokuapp.com/https://staging.workhorsescs.pro:8443';
  apiUrl = 'https://api.workhorsescs.pro';
  appUrl = 'https://staging.workhorsescs.pro';
}

if (
  hostname.includes('app.workhorsescs.pro') ||
  hostname.includes('wh-staging-fe.s3-website-us-east-1.amazonaws.com')
) {
  //apiUrl = 'https://cors-anywhere.herokuapp.com/https://staging.workhorsescs.pro:8443';
  messageServiceUrl = 'https://messaging-app.workhorsescs.pro';
  apiUrl = 'https://api-prod.workhorsescs.pro';
  appUrl = 'https://app.workhorsescs.pro';
}

export const base_url = `${apiUrl}/api/`;
export const base_url2 = `${apiUrl}`;
export const app_url = `${appUrl}`;

export const file_url = `${apiUrl}/storage/`;
export const message_service_url = `${messageServiceUrl}`;
