import * as actionCreators from '../actionTypes';

const initialState = {
  userList: [],
  listloading: false,
  listerror: null,
  currentUser: null,
  users: [],

  deleteMessage: null,
  isDelete: false,
  submitMessage: null,
  isSubmitted: false,

  assignedClientList: null,
  assignedClientListLoading: false,
  assignedClientListError: null,

  userRolesList: null,
  userRolesListLoading: false,
  userRolesListError: null,

  userEditData: null,
  userEditDataLoading: false,
  userEditDataError: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      };

    case actionCreators.GET_CURRENT_USER:
      return {
        ...state,
        currentUser: state.userList.filter(user => {
          let current;
          if (user.id === action.payload) {
            current = user;
          }
          return current;
        })
      };

    // user list
    case actionCreators.USER_LIST_CHECK:
      return {
        ...state,
        listloading: true,
        isDelete: false
      };

    case actionCreators.USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        listloading: false,
        isDelete: false
      };

    case actionCreators.USERS_SUCCESS:
      return {
        ...state,
        users: action.payload
      };
    case actionCreators.USER_LIST_FAIL:
      return {
        ...state,
        listerror: action.payload.request && action.payload.request.statusText,
        listloading: false,
        isDelete: false
      };

    // edit user
    case actionCreators.USER_EDIT_CHECK:
      return {
        ...state,
        userEditDataLoading: true,
        isDelete: false
      };

    case actionCreators.USER_EDIT_SUCCESS:
      return {
        ...state,
        userEditData: action.payload,
        userEditDataLoading: false,
        isDelete: false
      };
    case actionCreators.USER_EDIT_FAIL:
      return {
        ...state,
        userEditDataError:
          action.payload.request && action.payload.request.statusText,
        userEditDataLoading: false,
        isDelete: false
      };

    // clear
    case actionCreators.USER_CLEAR_CHECK:
      return {
        ...state,
        userEditData: null
      };

    // Service UPDATE
    case actionCreators.USER_UPDATE_CHECK:
      return {
        ...state,
        isSubmitted: false
      };
    case actionCreators.USER_UPDATE_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.USER_UPDATE_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    // delete user
    case actionCreators.USER_DELETE_CHECK:
      return {
        ...state,
        isDelete: false
      };
    case actionCreators.USER_DELETE_SUCCESS:
      return {
        ...state,
        isDelete: true,
        deleteMessage: action.payload
      };
    case actionCreators.USER_DELETE_FAIL:
      return {
        ...state,
        isDelete: false
      };

    // user add
    case actionCreators.USER_ADD_CHECK:
      return {
        ...state,
        isSubmitted: false
      };
    case actionCreators.USER_ADD_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        submitMessage: action.payload
      };
    case actionCreators.USER_ADD_FAIL:
      return {
        ...state,
        isSubmitted: false
      };

    // assigned Technician List

    case actionCreators.ASSIGNEDCLIENT_LIST_CHECK:
      return {
        ...state,
        assignedClientListLoading: true
      };

    case actionCreators.ASSIGNEDCLIENT_LIST_SUCCESS:
      return {
        ...state,
        assignedClientList: action.payload,
        assignedClientListLoading: false
      };
    case actionCreators.ASSIGNEDCLIENT_LIST_FAIL:
      return {
        ...state,
        assignedClientListError:
          action.payload.request && action.payload.request.statusText,
        assignedClientListLoading: false
      };

    // user roles List

    case actionCreators.USERROLES_LIST_CHECK:
      return {
        ...state,
        userRolesListLoading: true
      };

    case actionCreators.USERROLES_LIST_SUCCESS:
      return {
        ...state,
        userRolesList: action.payload,
        userRolesListLoading: false
      };
    case actionCreators.USERROLES_LIST_FAIL:
      return {
        ...state,
        userRolesListError:
          action.payload.request && action.payload.request.statusText,
        userRolesListLoading: false
      };

    default:
      return { ...state };
  }
};
export default userReducer;
