//1. Login API
export const LOGIN_CHECK = 'LOGINCHECK';
export const LOGIN_ERROR = 'LOGINERROR';
export const LOGIN_SUCCESS = 'LOGINESUCCESS';
export const LOGIN_TWOAUTH = 'LOGIN_TWOAUTH';
export const LOGIN_TWOAUTHSENDSMS = 'LOGIN_TWOAUTHSENDSMS';
export const LOGIN_TWOAUTHSENDSMS_SUCCESS = 'LOGIN_TWOAUTHSENDSMS_SUCCESS';
export const LOGIN_TWOAUTHSENDSMS_ERROR = 'LOGIN_TWOAUTHSENDSMS_ERROR';
export const LOGIN_BY_FORCE_PASSWORD_CHANGE = 'LOGIN_BY_FORCE_PASSWORD_CHANGE';
export const LOGIN_BY_FORCE_PASSWORD_DELETE = 'LOGIN_BY_FORCE_PASSWORD_DELETE';

export const LOGOUT_CHECK = 'LOGOUTCHECK';
export const LOGOUT_ERROR = 'LOGOUTERROR';
export const LOGOUT_SUCCESS = 'LOGOUTSUCCESS';

export const MTU_LOGIN_PROGRESS = 'MTULOGININPROGRESS';
export const MTU_LOGIN_FINISH = 'MTULOGINFINISH';
export const MTU_LOGIN_ERROR = 'MTULOGINERROR';
export const MTU_LOGIN_SUCCESS = 'MTULOGINSUCCESS';

export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_FAIL = 'CLIENT_UPDATE_FAIL';
export const CLIENT_UPDATE_CHECK = 'CLIENT_UPDATE_CHECK';

//2. Register
export const CHECK_EMAIL = 'CHECKEMAIL';
export const MAIL_ERROR = 'MAIL_ERROR';
export const MAIL_SUCCESS = 'MAIL_SUCCESS';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_ERROR = 'USER_REGISTRATION_ERROR';

//common
export const CS_LIST_SUCCESS = 'CS_LIST_SUCCESS';
export const CS_LIST_FAIL = 'CS_LIST_FAIL';
export const CS_LIST_CHECK = 'CS_LIST_CHECK';

// Test Data
export const CHECK_PASSCODE = 'CHECK_PASSCODE';
export const CHECK_PASSCODE_SUCCESS = 'CHECK_PASSCODE_SUCCESS';
export const CHECK_PASSCODE_FAIL = 'CHECK_PASSCODE_FAIL';

export const GET_TEST_CATEGORIES = 'GET_TEST_CATEGORIES';
export const GET_TEST_CATEGORIES_SUCCESS = 'GET_TEST_CATEGORIES_SUCCESS';
export const GET_TEST_CATEGORIES_FAIL = 'GET_TEST_CATEGORIES_FAIL';
export const SUBMIT_TEST_DETAILS = 'SUBMIT_TEST_DETAILS';
export const SUBMIT_TEST_DETAILS_SUCCESS = 'SUBMIT_TEST_DETAILS_SUCCESS';
export const SUBMIT_TEST_DETAILS_FAIL = 'SUBMIT_TEST_DETAILS_FAIL';

// user List 1
export const GET_ALLUSERLIST_CHECK = 'GET_ALLUSERLIST_CHECK';
export const GET_ALLUSERLIST_SUCCESS = 'GET_ALLUSERLIST_SUCCESS';
export const GET_ALLUSERLIST_FAIL = 'GET_ALLUSERLIST_FAIL';
// Brand List .2
export const GET_BRANDLIST_CHECK = 'GET_BRANDLIST_CHECK';
export const GET_BRANDLIST_SUCCESS = 'GET_BRANDLIST_SUCCESS';
export const GET_BRANDLIST_FAIL = 'GET_BRANDLIST_FAIL';
// Product List .2
export const GET_PRODUCTLIST_CHECK = 'GET_PRODUCTLIST_CHECK';
export const GET_PRODUCTLIST_SUCCESS = 'GET_PRODUCTLIST_SUCCESS';
export const GET_PRODUCTLIST_FAIL = 'GET_PRODUCTLIST_FAIL';

// Edit Profile
export const PROFILE_EDIT_CHECK = 'PROFILE_EDIT_CHECK';
export const PROFILE_EDIT_SUCCESS = 'PROFILE_EDIT_SUCCESS';
export const PROFILE_EDIT_FAIL = 'PROFILE_EDIT_FAIL';

// update Profile
export const PROFILE_UPDATE_CHECK = 'PROFILE_UPDATE_CHECK';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';

//   Delete tech list item
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';

//2.1 forgot password
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

//3. client
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_LIST_FAIL = 'CLIENT_LIST_FAIL';
export const CLIENT_LIST_CHECK = 'CLIENT_LIST_CHECK';
export const ADD_ADMIN_USER = 'ADD_ADMIN_USER';

//3.1 client Delete
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_FAIL';
export const CLIENT_DELETE_CHECK = 'CLIENT_DELETE_CHECK';

//CLIENT SETUP
export const STEP_1_SUBMIT = 'STEP_1_SUBMIT';
export const STEP_2_SUBMIT = 'STEP_2_SUBMIT';
export const STEP_3_SUBMIT = 'STEP_3_SUBMIT';
export const STEP_4_SUBMIT = 'STEP_4_SUBMIT';
export const STEP_5_SUBMIT = 'STEP_5_SUBMIT';
export const STEP_6_SUBMIT = 'STEP_6_SUBMIT';
export const STEP_7_SUBMIT = 'STEP_7_SUBMIT';
export const STEP_8_SUBMIT = 'STEP_8_SUBMIT';

export const HANDLE_SEARCH = 'HANDLE_SEARCH';

//CLIENT Add
export const CLIENT_ADD_SUCCESS = 'CLIENT_ADD_SUCCESS';
export const CLIENT_ADD_FAIL = 'CLIENT_ADD_FAIL';
export const CLIENT_ADD_CHECK = 'CLIENT_ADD_CHECK';

//CLIENT Edit
export const CLIENT_EDIT_SUCCESS = 'CLIENT_EDIT_SUCCESS';
export const CLIENT_EDIT_FAIL = 'CLIENT_EDIT_FAIL';
export const CLIENT_EDIT_CHECK = 'CLIENT_EDIT_CHECK';

//4. user
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_CHECK = 'USER_LIST_CHECK';

export const USER_CLEAR_CHECK = 'USER_CLEAR_CHECK';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';

//4.1 user Delete
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_CHECK = 'USER_DELETE_CHECK';

//4.2 user Add
export const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS';
export const USER_ADD_FAIL = 'USER_ADD_FAIL';
export const USER_ADD_CHECK = 'USER_ADD_CHECK';

//4.3. Assigned Client
export const ASSIGNEDCLIENT_LIST_SUCCESS = 'ASSIGNEDCLIENT_LIST_SUCCESS';
export const ASSIGNEDCLIENT_LIST_FAIL = 'ASSIGNEDCLIENT_LIST_FAIL';
export const ASSIGNEDCLIENT_LIST_CHECK = 'ASSIGNEDCLIENT_LIST_CHECK';

//4.1 user Update ACTIONTYPES
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_CHECK = 'USER_UPDATE_CHECK';

//4.2 user Roles list
export const USERROLES_LIST_SUCCESS = 'USERROLES_LIST_SUCCESS';
export const USERROLES_LIST_FAIL = 'USERROLES_LIST_FAIL';
export const USERROLES_LIST_CHECK = 'USERROLES_LIST_CHECK';

//   edit srvice
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_FAIL = 'USER_EDIT_FAIL';
export const USER_EDIT_CHECK = 'USER_EDIT_CHECK';

// clear Service
export const SERVICE_CLEAR_CHECK = 'SERVICE_CLEAR_CHECK';

//5. product
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';
export const PRODUCT_LIST_CHECK = 'PRODUCT_LIST_CHECK';

//delete product

//5.1 product
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_CHECK = 'PRODUCT_DELETE_CHECK';

//5.2 Add product
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';
export const PRODUCT_ADD_FAIL = 'PRODUCT_ADD_FAIL';
export const PRODUCT_ADD_CHECK = 'PRODUCT_ADD_CHECK';

//5.3 Product Edit
export const PRODUCT_EDIT_SUCCESS = 'PRODUCT_EDIT_SUCCESS';
export const PRODUCT_EDIT_FAIL = 'PRODUCT_EDIT_FAIL';
export const PRODUCT_EDIT_CHECK = 'PRODUCT_EDIT_CHECK';

//5.4 Product Update
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_CHECK = 'PRODUCT_UPDATE_CHECK';

//6. site
export const SITE_LIST_SUCCESS = 'SITE_LIST_SUCCESS';
export const SITE_LIST_FAIL = 'SITE_LIST_FAIL';
export const SITE_LIST_CHECK = 'SITE_LIST_CHECK';

//6.1 site
export const SITE_DELETE_SUCCESS = 'SITE_DELETE_SUCCESS';
export const SITE_DELETE_FAIL = 'SITE_LIST_FAIL';
export const SITE_DELETE_CHECK = 'SITE_DELETE_CHECK';

//4.1 site Add
export const SITE_ADD_SUCCESS = 'SITE_ADD_SUCCESS';
export const SITE_ADD_FAIL = 'SITE_ADD_FAIL';
export const SITE_ADD_CHECK = 'SITE_ADD_CHECK';

//4.1 site update
export const SITE_UPDATE_SUCCESS = 'SITE_UPDATE_SUCCESS';
export const SITE_UPDATE_FAIL = 'SITE_UPDATE_FAIL';
export const SITE_UPDATE_CHECK = 'SITE_UPDATE_CHECK';

//4.1 site update
export const SITE_EDIT_SUCCESS = 'SITE_EDIT_SUCCESS';
export const SITE_EDIT_FAIL = 'SITE_EDIT_FAIL';
export const SITE_EDIT_CHECK = 'SITE_EDIT_CHECK';

//7. company
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_FAIL = 'COMPANY_LIST_FAIL';
export const COMPANY_LIST_CHECK = 'COMPANY_LIST_CHECK';

//7. company
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_FAIL = 'COMPANY_DELETE_FAIL';
export const COMPANY_DELETE_CHECK = 'COMPANY_DELETE_CHECK';

//4.1 company Add
export const COMPANY_ADD_SUCCESS = 'COMPANY_ADD_SUCCESS';
export const COMPANY_ADD_FAIL = 'COMPANY_ADD_FAIL';
export const COMPANY_ADD_CHECK = 'COMPANY_ADD_CHECK';

//4.1 company Add
export const COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS';
export const COMPANY_EDIT_FAIL = 'COMPANY_EDIT_FAIL';
export const COMPANY_EDIT_CHECK = 'COMPANY_EDIT_CHECK';

//4.1 company UPDATE
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_FAIL = 'COMPANY_UPDATE_FAIL';
export const COMPANY_UPDATE_CHECK = 'COMPANY_UPDATE_CHECK';

// Inventory
export const INVENTORY_ADD_SUCCESS = 'INVENTORY_ADD_SUCCESS';
export const INVENTORY_ADD_FAIL = 'INVENTORY_ADD_FAIL';
export const INVENTORY_ADD_CHECK = 'INVENTORY_ADD_CHECK';

// manageInventory List Delete
export const INVENTORY_DELETE_SUCCESS = 'INVENTORY_DELETE_SUCCESS';
export const INVENTORY_DELETE_FAIL = 'INVENTORY_DELETE_FAIL';
export const INVENTORY_DELETE_CHECK = 'INVENTORY_DELETE_CHECK';

// Technician List
export const GET_TECHNICIANLIST_CHECK = 'GET_TECHNICIANLIST_CHECK';
export const GET_TECHNICIANLIST_SUCCESS = 'GET_TECHNICIANLIST_SUCCESS';
export const GET_TECHNICIANLIST_FAIL = 'GET_TECHNICIANLIST_FAIL';

// fetchInventory List
export const GET_FETCHINVENTORYLIST_CHECK = 'GET_FETCHINVENTORYLIST_CHECK';
export const GET_FETCHINVENTORYLIST_SUCCESS = 'GET_FETCHINVENTORYLIST_SUCCESS';
export const GET_FETCHINVENTORYLIST_FAIL = 'GET_FETCHINVENTORYLIST_FAIL';

//  update Inventory
export const INVENTORY_UPDATE_SUCCESS = 'INVENTORY_UPDATE_SUCCESS';
export const INVENTORY_UPDATE_FAIL = 'INVENTORY_UPDATE_FAIL';
export const INVENTORY_UPDATE_CHECK = 'INVENTORY_UPDATE_CHECK';

//  Edit Inventory
export const FETCHINVENTORY_EDIT_SUCCESS = 'FETCHINVENTORY_EDIT_SUCCESS';
export const FETCHINVENTORY_EDIT_FAIL = 'FETCHINVENTORY_EDIT_FAIL';
export const FETCHINVENTORY_EDIT_CHECK = 'FETCHINVENTORY_EDIT_CHECK';

export const GET_DEAL_PAYMENTS_SUCCESS = 'GET_DEAL_PAYMENTS_SUCCESS';
export const GET_DEAL_PAYMENTS_FAIL = 'GET_DEAL_PAYMENTS_FAIL';
export const GET_DEAL_PAYMENTS_CHECK = 'GET_DEAL_PAYMENTS_CHECK';

export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAIL = 'GET_PAYMENTS_FAIL';
export const GET_PAYMENTS_CHECK = 'GET_PAYMENTS_CHECK';

export const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS';
export const SUBMIT_PAYMENT_FAIL = 'SUBMIT_PAYMENT_FAIL';
export const SUBMIT_PAYMENT_CHECK = 'SUBMIT_PAYMENT_CHECK';

export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';
export const GET_PAYMENT_METHODS_CHECK = 'GET_PAYMENT_METHODS_CHECK';

//8. contact
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
export const CONTACT_LIST_FAIL = 'CONTACT_LIST_FAIL';
export const CONTACT_LIST_CHECK = 'CONTACT_LIST_CHECK';

//8.1 contact
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_DELETE_FAIL = 'CONTACT_DELETE_FAIL';
export const CONTACT_DELETE_CHECK = 'CONTACT_DELETE_CHECK';

//4.1 Contact Add
export const CONTACT_ADD_SUCCESS = 'CONTACT_ADD_SUCCESS';
export const CONTACT_ADD_FAIL = 'CONTACT_ADD_FAIL';
export const CONTACT_ADD_CHECK = 'CONTACT_ADD_CHECK';

//4.1 Contact Update
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_FAIL = 'CONTACT_UPDATE_FAIL';
export const CONTACT_UPDATE_CHECK = 'CONTACT_UPDATE_CHECK';

//4.1 Contact Edit
export const CONTACT_EDIT_SUCCESS = 'CONTACT_EDIT_SUCCESS';
export const CONTACT_EDIT_FAIL = 'CONTACT_EDIT_FAIL';
export const CONTACT_EDIT_CHECK = 'CONTACT_EDIT_CHECK';

//get CS count dashboard
export const CS_COUNT_SUCCESS = 'CS_COUNT_SUCCESS';
export const CS_COUNT_FAIL = 'CS_COUNT_FAIL';
export const CS_COUNT_CHECK = 'CS_COUNT_CHECK';

// Product List .2
export const GET_INVENTORYLIST_CHECK = 'GET_INVENTORYLIST_CHECK';
export const GET_INVENTORYLIST_SUCCESS = 'GET_INVENTORYLIST_SUCCESS';
export const GET_INVENTORYLIST_FAIL = 'GET_INVENTORYLIST_FAIL';

//9. deals
export const DEAL_LIST_SUCCESS = 'DEAL_LIST_SUCCESS';
export const DEAL_LIST_FAIL = 'DEAL_LIST_FAIL';
export const DEAL_LIST_CHECK = 'DEAL_LIST_CHECK';

export const DEAL_SYSTEM_LIST_SUCCESS = 'DEAL_SYSTEM_LIST_SUCCESS';
export const DEAL_SYSTEM_LIST_FAIL = 'DEAL_SYSTEM_LIST_FAIL';
export const DEAL_SYSTEM_LIST_CHECK = 'DEAL_SYSTEM_LIST_CHECK';

// EVENT LIST
export const EVENT_LIST_CHECK = 'EVENT_LIST_CHECK';
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS';
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL';

//9.1 deals dealte
export const DEAL_DELETE_SUCCESS = 'DEAL_DELETE_SUCCESS';
export const DEAL_DELETE_FAIL = 'DEAL_DELETE_FAIL';
export const DEAL_DELETE_CHECK = 'DEAL_DELETE_CHECK';

export const EDITDEAL_SUCCESS = 'EDITDEAL_SUCCESS';
export const EDITDEAL_FAIL = 'EDITDEAL_FAIL';
export const EDITDEAL_CHECK = 'EDITDEAL_CHECK';

export const DEAL_ADD_CHECK = 'DEAL_ADD_CHECK';
export const DEAL_ADD_FAIL = 'DEAL_ADD_FAIL';
export const DEAL_ADD_SUCCESS = 'DEAL_ADD_SUCCESS';

//9. services
export const SERVICE_LIST_SUCCESS = 'SERVICE_LIST_SUCCESS';
export const SERVICE_LIST_FAIL = 'SERVICE_LIST_FAIL';
export const SERVICE_LIST_CHECK = 'SERVICE_LIST_CHECK';

//9.1 SERVICEs SERVICEte
export const SERVICE_DELETE_SUCCESS = 'SERVICE_DELETE_SUCCESS';
export const SERVICE_DELETE_FAIL = 'SERVICE_DELETE_FAIL';
export const SERVICE_DELETE_CHECK = 'SERVICE_DELETE_CHECK';

//9.2 Service Create
export const SERVICE_CREATE_CHECK = 'SERVICE_CREATE_CHECK';
export const SERVICE_CREATE_FAIL = 'SERVICE_CREATE_FAIL';
export const SERVICE_CREATE_SUCCESS = 'SERVICE_CREATE_SUCCESS';

//  Service Inventory
export const SERVICE_UPDATE_SUCCESS = 'SERVICE_UPDATE_SUCCESS';
export const SERVICE_UPDATE_FAIL = 'SERVICE_UPDATE_FAIL';
export const SERVICE_UPDATE_CHECK = 'SERVICE_UPDATE_CHECK';

//   edit srvice
export const SERVICE_EDIT_SUCCESS = 'SERVICE_EDIT_SUCCESS';
export const SERVICE_EDIT_FAIL = 'SERVICE_EDIT_FAIL';
export const SERVICE_EDIT_CHECK = 'SERVICE_EDIT_CHECK';

export const SERVICE_ADD_CHECK = 'SERVICE_ADD_CHECK';
export const SERVICE_ADD_FAIL = 'SERVICE_ADD_FAIL';
export const SERVICE_ADD_SUCCESS = 'SERVICE_ADD_SUCCESS';

export const SERVICE_START_TIMER_CHECK = 'SERVICE_START_TIMER_CHECK';
export const SERVICE_START_TIMER_FAIL = 'SERVICE_START_TIMER_FAIL';
export const SERVICE_START_TIMER_SUCCESS = 'SERVICE_START_TIMER_SUCCESS';

export const SERVICE_STOP_TIMER_CHECK = 'SERVICE_STOP_TIMER_CHECK';
export const SERVICE_STOP_TIMER_FAIL = 'SERVICE_STOP_TIMER_FAIL';
export const SERVICE_STOP_TIMER_SUCCESS = 'SERVICE_STOP_TIMER_SUCCESS';

export const SERVICE_CHANGESTATUS_CHECK = 'SERVICE_CHANGESTATUS_CHECK';

// NEW DEAL
export const DEAL_STAGE_1_CHECK = 'DEAL_STAGE_1_CHECK';
export const DEAL_STAGE_1_SUCCESS = 'DEAL_STAGE_1_SUCCESS';
export const DEAL_STAGE_1_FAIL = 'DEAL_STAGE_1_FAIL';

export const DEAL_STAGE_2_CHECK = 'DEAL_STAGE_2_CHECK';
export const DEAL_STAGE_2_SUCCESS = 'DEAL_STAGE_2_SUCCESS';
export const DEAL_STAGE_2_FAIL = 'DEAL_STAGE_2_FAIL';

export const DEAL_STAGE_3_CHECK = 'DEAL_STAGE_3_CHECK';
export const DEAL_STAGE_3_SUCCESS = 'DEAL_STAGE_3_SUCCESS';
export const DEAL_STAGE_3_FAIL = 'DEAL_STAGE_3_FAIL';

export const DEAL_STAGE_4_CHECK = 'DEAL_STAGE_4_CHECK';
export const DEAL_STAGE_4_SUCCESS = 'DEAL_STAGE_4_SUCCESS';
export const DEAL_STAGE_4_FAIL = 'DEAL_STAGE_4_FAIL';

export const DEAL_STAGE_5_CHECK = 'DEAL_STAGE_5_CHECK';
export const DEAL_STAGE_5_SUCCESS = 'DEAL_STAGE_5_SUCCESS';
export const DEAL_STAGE_5_FAIL = 'DEAL_STAGE_5_FAIL';

export const SET_TYPE_AND_STAGE = 'SET_TYPE_AND_STAGE';

export const GET_DEALS_CONTACT_LIST_CHECK = 'GET_DEALS_CONTACT_LIST_CHECK';
export const GET_DEALS_CONTACT_LIST_SUCCESS = 'GET_DEALS_CONTACT_LIST_SUCCESS';
export const GET_DEALS_CONTACT_LIST_FAIL = 'GET_DEALS_CONTACT_LIST_FAIL';

//Technician Schedule
export const GET_TECH_SCHEDULE_CHECK = 'GET_TECH_SCHEDULE_CHECK';
export const GET_TECH_SCHEDULE_SUCCESS = 'GET_TECH_SCHEDULE_SUCCESS';
export const GET_TECH_SCHEDULE_FAIL = 'GET_TECH_SCHEDULE_FAIL';

// scheduleList

//  SUBSCRIPTION ACTIONS
export const SET_CURRENT_SUBSCRIPTION = 'SET_CURRENT_SUBSCRIPTION';
export const GET_CURRENT_SUBSCRIPTION = 'GET_CURRENT_SUBSCRIPTION';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAIL = 'GET_SUBSCRIPTIONS_FAIL';

export const SUBSCRIPTION_CANCEL_CHECK = 'SUBSCRIPTION_CANCEL_CHECK';
export const SUBSCRIPTION_CANCEL_SUCCESS = 'SUBSCRIPTION_CANCEL_SUCCESS';
export const SUBSCRIPTION_CANCEL_FAIL = 'SUBSCRIPTION_CANCEL_FAIL';

export const SUBSCRIPTION_PAUSE_CHECK = 'SUBSCRIPTION_PAUSE_CHECK';
export const SUBSCRIPTION_PAUSE_SUCCESS = 'SUBSCRIPTION_PAUSE_SUCCESS';
export const SUBSCRIPTION_PAUSE_FAIL = 'SUBSCRIPTION_PAUSE_FAIL';

export const SUBSCRIPTION_RESUME_CHECK = 'SUBSCRIPTION_RESUME_CHECK';
export const SUBSCRIPTION_RESUME_SUCCESS = 'SUBSCRIPTION_RESUME_SUCCESS';
export const SUBSCRIPTION_RESUME_FAIL = 'SUBSCRIPTION_RESUME_FAIL';

export const SUBSCRIPTION_ADD_CHECK = 'SUBSCRIPTION_ADD_CHECK';
export const SUBSCRIPTION_ADD_SUCCESS = 'SUBSCRIPTION_ADD_SUCCESS';
export const SUBSCRIPTION_ADD_FAIL = 'SUBSCRIPTION_ADD_FAIL';

export const SUBSCRIPTION_UPDATE_CHECK = 'SUBSCRIPTION_UPDATE_CHECK';
export const SUBSCRIPTION_UPDATE_SUCCESS = 'SUBSCRIPTION_UPDATE_SUCCESS';
export const SUBSCRIPTION_UPDATE_FAIL = 'SUBSCRIPTION_UPDATE_FAIL';

// BRANDS BY CLIENT
export const BRANDLIST_CLIENT_CHECK = 'BRANDLIST_CLIENT_CHECK';
export const BRANDLIST_CLIENT_CHECK_SUCCESS = 'BRANDLIST_CLIENT_CHECK_SUCCESS';
export const BRANDLIST_CLIENT_CHECK_FAIL = 'BRANDLIST_CLIENT_CHECK_FAIL';

// PRODUCTS BY BRAND
export const PRODUCTLIST_BRAND_CHECK = 'PRODUCTLIST_BRAND_CHECK';
export const PRODUCTLIST_BRAND_SUCCESS = 'PRODUCTLIST_BRAND_SUCCESS';
export const PRODUCTLIST_BRAND_FAIL = 'PRODUCTLIST_BRAND_FAIL';

export const GET_CENTRAL_STATIONS = 'GET_CENTRAL_STATIONS';
export const GET_CENTRAL_STATIONS_SUCCESS = 'GET_CENTRAL_STATIONS_SUCCESS';
export const GET_CENTRAL_STATIONS_FAIL = 'GET_CENTRAL_STATIONS_FAIL';

export const GET_CENTRAL_STATION_ADDONS = 'GET_CENTRAL_STATION_ADDONS';
export const GET_CENTRAL_STATION_ADDONS_SUCCESS =
  'GET_CENTRAL_STATION_ADDONS_SUCCESS';
export const GET_CENTRAL_STATION_ADDONS_FAIL =
  'GET_CENTRAL_STATION_ADDONS_FAIL';

export const GET_COMM_PACKAGES = 'GET_COMM_PACKAGES';
export const GET_COMM_PACKAGES_SUCCESS = 'GET_COMM_PACKAGES_SUCCESS';
export const GET_COMM_PACKAGES_FAIL = 'GET_COMM_PACKAGES_FAIL';

export const GET_COMM_PACKAGE_ADDONS = 'GET_COMM_PACKAGE_ADDONS';
export const GET_COMM_PACKAGE_ADDONS_SUCCESS =
  'GET_COMM_PACKAGE_ADDONS_SUCCESS';
export const GET_COMM_PACKAGE_ADDONS_FAIL = 'GET_COMM_PACKAGE_ADDONS_FAIL';

export const GET_DEAL_TEMPLATES = 'GET_DEAL_TEMPLATES';
export const GET_DEAL_TEMPLATES_SUCCESS = 'GET_DEAL_TEMPLATES_SUCCESS';
export const GET_DEAL_TEMPLATES_FAIL = 'GET_DEAL_TEMPLATES_FAIL';

// BACKGROUND CHECK
export const RUN_BG_CHECK = 'RUN_BG_CHECK';
export const RUN_BG_CHECK_SUCCESS = 'RUN_BG_CHECK_SUCCESS';
export const RUN_BG_CHECK_FAIL = 'RUN_BG_CHECK_FAIL';

export const GET_BG_TYPES = 'GET_BG_TYPES';
export const GET_BG_TYPES_SUCCESS = 'GET_BG_TYPES_SUCCESS';
export const GET_BG_TYPES_FAIL = 'GET_BG_TYPES_FAIL';

export const GET_BG_STATUS = 'GET_BG_STATUS';
export const GET_BG_STATUS_SUCCESS = 'GET_BG_STATUS_SUCCESS';
export const GET_BG_STATUS_FAIL = 'GET_BG_STATUS_FAIL';

export const GET_PREV_BG_TYPES = 'GET_PREV_BG_TYPES';
export const GET_PREV_BG_TYPES_SUCCESS = 'GET_PREV_BG_TYPES_SUCCESS';
export const GET_PREV_BG_TYPES_FAIL = 'GET_PREV_BG_TYPES_FAIL';

export const GET_NOTES = 'GET_NOTES';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_FAIL = 'GET_NOTES_FAIL';

export const ADD_NOTE = 'ADD_NOTE';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_FAIL = 'ADD_NOTE_FAIL';

export const SELECT_PACKAGE = 'SELECT_PACKAGE';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';

// ONBOARDING
export const WH_PACKAGE_CHECK = 'WH_PACKAGE_CHECK';
export const WH_PACKAGE_SUCCESS = 'WH_PACKAGE_SUCCESS';
export const WH_PACKAGE_FAIL = 'WH_PACKAGE_FAIL';
export const EDIT_TAX_SETTING = 'EDIT_TAX_SETTING';

export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const GET_PAYMENT_METHOD_FAIL = 'GET_PAYMENT_METHOD_FAIL';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';

export const GET_DEAL_TYPE = 'GET_DEAL_TYPE';
export const GET_DEAL_TYPE_SUCCESS = 'GET_DEAL_TYPE_SUCCESS';
export const GET_DEAL_TYPE_FAIL = 'GET_DEAL_TYPE_FAIL';

export const ALL_BRANDLIST_CLIENT_CHECK = 'ALL_BRANDLIST_CLIENT_CHECK';
export const ALL_BRANDLIST_CLIENT_CHECK_SUCCESS =
  'ALL_BRANDLIST_CLIENT_CHECK_SUCCESS';
export const ALL_BRANDLIST_CLIENT_CHECK_FAIL =
  'ALL_BRANDLIST_CLIENT_CHECK_FAIL';

export const OVERVIEW_DETAILS_CHECK = 'OVERVIEW_DETAILS_CHECK';
export const OVERVIEW_DETAILS_SUCCESS = 'OVERVIEW_DETAILS_SUCCESS';
export const OVERVIEW_DETAILS_FAIL = 'OVERVIEW_DETAILS_FAIL';

export const GET_SVC_CALL_INFO = 'GET_SVC_CALL_INFO';
export const GET_SVC_CALL_INFO_SUCCESS = 'GET_SVC_CALL_INFO_SUCCESS';
export const GET_SVC_CALL_INFO_FAIL = 'GET_SVC_CALL_INFO_FAIL';

export const GET_RELATIONSHIPS_CHECK = 'GET_RELATIONSHIPS_CHECK';
export const GET_RELATIONSHIPS_SUCCESS = 'GET_RELATIONSHIPS_SUCCESS';
export const GET_RELATIONSHIPS_FAIL = 'GET_RELATIONSHIPS_FAIL';

export const GET_ZONE_LIST = 'GET_ZONE_LIST';
export const GET_ZONE_LIST_SUCCESS = 'GET_ZONE_LIST_SUCCESS';
export const GET_ZONE_LIST_FAIL = 'GET_ZONE_LIST_FAIL';

export const PUSH_ZONE_LIST = 'PUSH_ZONE_LIST';
export const PUSH_ZONE_LIST_SUCCESS = 'PUSH_ZONE_LIST_SUCCESS';
export const PUSH_ZONE_LIST_FAIL = 'PUSH_ZONE_LIST_FAIL';

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAIL = 'ADD_PAYMENT_METHOD_FAIL';

//SETTINGS BY TYPE
export const SETTINGS_BY_TYPE_SUCCESS = 'SETTINGS_BY_TYPE_SUCCESS';
export const SETTINGS_BY_TYPE_FAIL = 'SETTINGS_BY_TYPE_FAIL';
export const SETTINGS_BY_TYPE_CHECK = 'SETTINGS_BY_TYPE_CHECK';

//SETTINGS
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAIL = 'SETTINGS_FAIL';
export const SETTINGS_CHECK = 'SETTINGS_CHECK';

//SAVE SETTINGS
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_FAIL = 'SAVE_SETTINGS_FAIL';
export const SAVE_SETTINGS_CHECK = 'SAVE_SETTINGS_CHECK';

//DEAL ALARM ACTIVATE
export const DEAL_ALARM_ACTIVATE_SUCCESS = 'DEAL_ALARM_ACTIVATE_SUCCESS';
export const DEAL_ALARM_ACTIVATE_FAIL = 'DEAL_ALARM_ACTIVATE_FAIL';
export const DEAL_ALARM_ACTIVATE_CHECK = 'DEAL_ALARM_ACTIVATE_CHECK';

//DEAL ALARM SEND WELCOME
export const DEAL_ALARM_SEND_WELCOME_SUCCESS =
  'DEAL_ALARM_SEND_WELCOME_SUCCESS';
export const DEAL_ALARM_SEND_WELCOME_FAIL = 'DEAL_ALARM_SEND_WELCOME_FAIL';
export const DEAL_ALARM_SEND_WELCOME_CHECK = 'DEAL_ALARM_SEND_WELCOME_CHECK';

//DEAL ALARM TRY TO SYNC
export const DEAL_ALARM_TRY_TO_SYNC_SUCCESS = 'DEAL_ALARM_TRY_TO_SYNC_SUCCESS';
export const DEAL_ALARM_TRY_TO_SYNC_FAIL = 'DEAL_ALARM_TRY_TO_SYNC_FAIL';
export const DEAL_ALARM_TRY_TO_SYNC_CHECK = 'DEAL_ALARM_TRY_TO_SYNC_CHECK';

//DEAL ALARM TRY TO UNSYNC
export const DEAL_ALARM_TRY_TO_UNSYNC_SUCCESS = 'DEAL_ALARM_TRY_TO_UNSYNC_SUCCESS';
export const DEAL_ALARM_TRY_TO_UNSYNC_FAIL = 'DEAL_ALARM_TRY_TO_UNSYNC_FAIL';
export const DEAL_ALARM_TRY_TO_UNSYNC_CHECK = 'DEAL_ALARM_TRY_TO_UNSYNC_CHECK';

//DEAL ALARM TERMINATE
export const DEAL_ALARM_TERMINATE_SUCCESS = 'DEAL_ALARM_TERMINATE_SUCCESS';
export const DEAL_ALARM_TERMINATE_FAIL = 'DEAL_ALARM_TERMINATE_FAIL';
export const DEAL_ALARM_TERMINATE_CHECK = 'DEAL_ALARM_TERMINATE_CHECK';

//DEAL ALARM GET LIVE DATA
export const DEAL_ALARM_GET_LIVE_DATA_SUCCESS =
  'DEAL_ALARM_GET_LIVE_DATA_SUCCESS';
export const DEAL_ALARM_GET_LIVE_DATA_FAIL = 'DEAL_ALARM_GET_LIVE_DATA_FAIL';
export const DEAL_ALARM_GET_LIVE_DATA_CHECK = 'DEAL_ALARM_GET_LIVE_DATA_CHECK';

//CLIENT CREATE
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_CREATE_FAIL = 'CLIENT_CREATE_FAIL';
export const CLIENT_CREATE_CHECK = 'CLIENT_CREATE_CHECK';

//CREATE SETTING
export const CREATE_SETTING_SUCCESS = 'CREATE_SETTING_SUCCESS';
export const CREATE_SETTING_FAIL = 'CREATE_SETTING_FAIL';
export const CREATE_SETTING_CHECK = 'CREATE_SETTING_CHECK';

export const CLEAR = 'CLEAR';

// TASKS
export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAIL = 'CREATE_TASK_FAIL';

export const COMPLETE_TASK = 'COMPLETE_TASK';
export const COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS';
export const COMPLETE_TASK_FAIL = 'COMPLETE_TASK_FAIL';

export const ASSIGN_TASK = 'ASSIGN_TASK';
export const ASSIGN_TASK_SUCCESS = 'ASSIGN_TASK_SUCCESS';
export const ASSIGN_TASK_FAIL = 'ASSIGN_TASK_FAIL';

export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL';

export const GET_TASK_LIST = 'GET_TASK_LIST';
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS';
export const GET_TASK_LIST_FAIL = 'GET_TASK_LIST_FAIL';

export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';

export const DEAL_ALARM_IN_SERVICE_CHECK = 'DEAL_ALARM_IN_SERVICE_CHECK';
export const DEAL_ALARM_IN_SERVICE_SUCCESS = 'DEAL_ALARM_IN_SERVICE_SUCCESS';
export const DEAL_ALARM_IN_SERVICE_FAIL = 'DEAL_ALARM_IN_SERVICE_FAIL';

export const DEAL_ALARM_OUT_OF_SERVICE_CHECK =
  'DEAL_ALARM_OUT_OF_SERVICE_CHECK';
export const DEAL_ALARM_OUT_OF_SERVICE_SUCCESS =
  'DEAL_ALARM_OUT_OF_SERVICE_SUCCESS';
export const DEAL_ALARM_OUT_OF_SERVICE_FAIL = 'DEAL_ALARM_OUT_OF_SERVICE_FAIL';

export const HANDLE_SUBSCRIPTION_MODAL = 'HANDLE_SUBSCRIPTION_MODAL';

export const HANDLE_NEW_PAYMENT_MODAL = 'HANDLE_NEW_PAYMENT_MODAL';
export const DEAL_ALARM_CS_UPDATE_CHECK = 'DEAL_ALARM_CS_UPDATE_CHECK';
export const DEAL_ALARM_CS_UPDATE_SUCCESS = 'DEAL_ALARM_CS_UPDATE_SUCCESS';
export const DEAL_ALARM_CS_UPDATE_FAIL = 'DEAL_ALARM_CS_UPDATE_FAIL';

export const DEAL_ALARM_OUT_OF_TEST_CHECK = 'DEAL_ALARM_OUT_OF_TEST_CHECK';
export const DEAL_ALARM_OUT_OF_TEST_SUCCESS = 'DEAL_ALARM_OUT_OF_TEST_SUCCESS';
export const DEAL_ALARM_OUT_OF_TEST_FAIL = 'DEAL_ALARM_OUT_OF_TEST_FAIL';

export const DEAL_ALARM_IMEI_VERIFY_CHECK = 'DEAL_ALARM_IMEI_VERIFY_CHECK';
export const DEAL_ALARM_IMEI_VERIFY_SUCCESS = 'DEAL_ALARM_IMEI_VERIFY_SUCCESS';
export const DEAL_ALARM_IMEI_VERIFY_FAIL = 'DEAL_ALARM_IMEI_VERIFY_FAIL';

export const DEAL_ALARM_ALARM_UPDATE_CHECK = 'DEAL_ALARM_ALARM_UPDATE_CHECK';
export const DEAL_ALARM_ALARM_UPDATE_SUCCESS =
  'DEAL_ALARM_ALARM_UPDATE_SUCCESS';
export const DEAL_ALARM_ALARM_UPDATE_FAIL = 'DEAL_ALARM_ALARM_UPDATE_FAIL';

export const GET_DEAL_SELECTED_ITEMS_CHECK = 'GET_DEAL_SELECTED_ITEMS_CHECK';
export const GET_DEAL_SELECTED_ITEMS_SUCCESS =
  'GET_DEAL_SELECTED_ITEMS_SUCCESS';
export const GET_DEAL_SELECTED_ITEMS_FAIL = 'GET_DEAL_SELECTED_ITEMS_FAIL';

export const SETTING_TYPES_CHECK = 'SETTING_TYPES_CHECK';
export const SETTING_TYPES_SUCCESS = 'SETTING_TYPES_SUCCESS';
export const SETTING_TYPES_FAIL = 'SETTING_TYPES_FAIL';

export const GET_SETTING_FOR_MODEL_BY_NAME_CHECK =
  'GET_SETTING_FOR_MODEL_BY_NAME_CHECK';
export const GET_SETTING_FOR_MODEL_BY_NAME_SUCCESS =
  'GET_SETTING_FOR_MODEL_BY_NAME_SUCCESS';
export const GET_SETTING_FOR_MODEL_BY_NAME_FAIL =
  'GET_SETTING_FOR_MODEL_BY_NAME_FAIL';

export const GET_TAX_BY_ZIP_CHECK = 'GET_TAX_BY_ZIP_CHECK';
export const GET_TAX_BY_ZIP_SUCCESS = 'GET_TAX_BY_ZIP_SUCCESS';
export const GET_TAX_BY_ZIP_FAIL = 'GET_TAX_BY_ZIP_FAIL';

export const CLIENT_CENTRAL_STATION_TEMPLATES_CHECK =
  'CLIENT_CENTRAL_STATION_TEMPLATES_CHECK';
export const CLIENT_CENTRAL_STATION_TEMPLATES_SUCCESS =
  'CLIENT_CENTRAL_STATION_TEMPLATES_SUCCESS';
export const CLIENT_CENTRAL_STATION_TEMPLATES_FAIL =
  'CLIENT_CENTRAL_STATION_TEMPLATES_FAIL';

export const CLIENT_CENTRAL_STATION_TEMPLATES_UPDATE_CHECK =
  'CLIENT_CENTRAL_STATION_TEMPLATES_UPDATE_CHECK';
export const CLIENT_CENTRAL_STATION_TEMPLATES_UPDATE_SUCCESS =
  'CLIENT_CENTRAL_STATION_TEMPLATES_UPDATE_SUCCESS';
export const CLIENT_CENTRAL_STATION_TEMPLATES_UPDATE_FAIL =
  'CLIENT_CENTRAL_STATION_TEMPLATES_UPDATE_FAIL';

export const LOGO_SUCCESS = 'LOGO_SUCCESS';

export const UPDATE_CLIENT_INFO = 'UPDATE_CLIENT_INFO';
export const UPDATE_CLIENT_INFO_SUCCESS = 'UPDATE_CLIENT_INFO_SUCCESS';
export const UPDATE_CLIENT_INFO_FAIL = 'UPDATE_CLIENT_INFO_FAIL';

export const UPDATE_CLIENT_LICENSE = 'UPDATE_CLIENT_LICENSE';
export const UPDATE_CLIENT_LICENSE_SUCCESS = 'UPDATE_CLIENT_LICENSE_SUCCESS';
export const UPDATE_CLIENT_LICENSE_FAIL = 'UPDATE_CLIENT_LICENSE_FAIL';

export const CLIENT_CENTRAL_STATION_CHECK = 'CLIENT_CENTRAL_STATION_CHECK';
export const CLIENT_CENTRAL_STATION_SUCCESS = 'CLIENT_CENTRAL_STATION_SUCCESS';
export const CLIENT_CENTRAL_STATION_FAIL = 'CLIENT_CENTRAL_STATION_FAIL';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const FETCH_INVOICES_FAIL = 'FETCH_INVOICES_FAIL';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';

export const USERS_CHECK = 'USERS_CHECK';
export const USERS_FAIL = 'USERS_FAIL';
export const USERS_SUCCESS = 'USERS_SUCCESS';

export const ALL_PRODUCTS_CHECK = 'ALL_PRODUCTS_CHECK';
export const ALL_PRODUCTS_FAIL = 'ALL_PRODUCTS_FAIL';
export const ALL_PRODUCTS_SUCCESS = 'ALL_PRODUCTS_SUCCESS';

export const GET_SUBSCRIPTION_BY_ID = 'GET_SUBSCRIPTION_BY_ID';
export const GET_SUBSCRIPTION_BY_ID_FAIL = 'GET_SUBSCRIPTION_BY_ID_FAIL';
export const GET_SUBSCRIPTION_BY_ID_SUCCESS = 'GET_SUBSCRIPTION_BY_ID_SUCCESS';

export const GET_DEAL_COMPONENTS_TO_SHOW_CHECK =
  'GET_DEAL_COMPONENTS_TO_SHOW_CHECK';
export const GET_DEAL_COMPONENTS_TO_SHOW_FAIL =
  'GET_DEAL_COMPONENTS_TO_SHOW_FAIL';
export const GET_DEAL_COMPONENTS_TO_SHOW_SUCCESS =
  'GET_DEAL_COMPONENTS_TO_SHOW_SUCCESS';

export const CENTRAL_STATION_DISPATCHES_CHECK =
  'CENTRAL_STATION_DISPATCHES_CHECK';
export const CENTRAL_STATION_DISPATCHES_FAIL =
  'CENTRAL_STATION_DISPATCHES_FAIL';
export const CENTRAL_STATION_DISPATCHES_SUCCESS =
  'CENTRAL_STATION_DISPATCHES_SUCCESS';

export const CHAT_NOTIFICATION_CHECK = 'CHAT_NOTIFICATION_CHECK';
export const CHAT_NOTIFICATION_FAIL = 'CHAT_NOTIFICATION_FAIL';
export const CHAT_NOTIFICATION_SUCCESS = 'CHAT_NOTIFICATION_SUCCESS';

export const ALL_SYSTEMS_SUCCESS = 'ALL_SYSTEMS_SUCCESS';
export const ALL_SYSTEMS_ERROR = 'ALL_SYSTEMS_ERROR';

export const RUN_EMERGENCY_CONTACT_UPDATE = 'RUN_EMERGENCY_CONTACT_UPDATE';
export const RUN_EMERGENCY_CONTACT_UPDATE_ERROR = 'RUN_EMERGENCY_CONTACT_UPDATE_ERROR';
